export class PaymentModel{
    paymentMethodToken: string;
    membershipPlanCode: string;
    membershipPlanId: string;
    code: string;
    promoCode: string;
}

export class RealtorPlan {
    description: string;
    code: string;
    title: string;
    membershipId: number;
    frequency: string;
    price: string;
    membershipType: string;
    featureSet: string;
    promoCode: string;
    uid: string;
    isa: boolean;
    planCategoryDescription: string;
  }