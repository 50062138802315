import { Component, OnInit, SecurityContext, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as dropin from 'braintree-web-drop-in';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { RealtorRegModel } from 'src/app/models/RealtorRegModel';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { CommonConstants } from 'src/app/constants/commonConstants';
import { LoginService } from 'src/app/services/login/login.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as states from '../../../assets/data/states.json';
import { PlanDetails } from 'src/app/constants/PlanDetails.js';
import { HubspotContactModel } from 'src/app/models/hubspotContactModel';
import { HubspotService } from 'src/app/services/hubspot/hubspot.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { TmsModel } from 'src/app/models/TmsModel';
import { RealtorService } from 'src/app/services/realtors/realtor.service';


@Component({
  selector: 'app-new-signup',
  templateUrl: './new-signup.component.html',
  styleUrls: ['./new-signup.component.scss']
})
export class NewRealtorSignupComponent implements OnInit {

  realtorSignUpForm: FormGroup;
  showBenefits: boolean = true;
  showMoreFields: boolean = false;
  isMobile: boolean = false;
  submitted = false;
  dropInInstance: dropin.DropinModel;
  xClientId: string;
  states = [];
  mlsAffiliates: any[];
  mbrshipBenefits: string[] = [];
  termsAndConditons: any;
  tcURL: SafeResourceUrl;
  tcHeader: string = '';
  tomorrowDate: string = '';
  membershipRealtorsPlans: [] = [] as any;
  errorMsg: string = '';
  displayEmailError: boolean = false;
  errorMessage: string = '';
  // tmsArray: string[] = ["None","Dotloop", "DocuSign"];
  token: string = '';
  tmsArray: TmsModel[] = [
    { value: 'None', viewValue: "None"},
    { value: 'DotLoop', viewValue: "DotLoop" },
    { value: 'DocuSign',viewValue: "DocuSign" }
  ];
  defaultTMS: string[] = ['None'];
  selectedPlan: string = '';
	iOSStoreUrl:string;
	androidStoreUrl:string;

  constructor(
    private formBuilder: FormBuilder,
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private loginService: LoginService,
    private hubSpotService: HubspotService,
    private realtorService: RealtorService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.xClientId = this.environmentService.getXClientId();
    this.mbrshipBenefits = PlanDetails.REALTOR_SIGNUP_FEATURES;
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    this.tomorrowDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    this.getMLSAffilates();
    this.buildSignUpForm();
    this.showHideBenefits(window.innerWidth);
    this.showHideMoreFields();
    this.states = (states as any).default;
    this.androidStoreUrl=this.environmentService.getAndroidStoreUrl();
		this.iOSStoreUrl=this.environmentService.getiOSStoreUrl();
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showHideBenefits(event.target.innerWidth);
  }

  showHideBenefits(width) {
    if (width <= 1023) {
      if (this.isMobile == false) {
        this.isMobile = true;
        this.showBenefits = false;
      }
    }
    else {
      this.isMobile = false;
      this.showBenefits = true;
    }
  }

  showHideMoreFields() {
      if (this.showMoreFields === false) {
        this.showMoreFields = true;
      }else {
        this.showMoreFields = false;
      }
  }

  getMLSAffilates() {
    const url = this.environmentService.getIA9V2Url() + "MlsAffiliates/?code=" + this.environmentService.getIA9V2Key();
    this.spinner.show();
    this.professionalService.getMLSAffiliates(url).subscribe(response => {
      this.spinner.hide();
      this.mlsAffiliates = response;
    });
  }

  buildSignUpForm() {
    this.realtorSignUpForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      mobileNumber: new FormControl(null, [Validators.required, Validators.pattern("[0-9\+\-]{10,12}")]),
      emailId: new FormControl('', [Validators.required, Validators.email]),
      companyName: new FormControl(''),
      companyUrl: new FormControl('', [Validators.pattern(/^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})([\/\w .-]*)\??([^\s]*)$/i)]),
      licenseNumber: new FormControl(''),
      licenseState: new FormControl(''),
      licenseExpiration: new FormControl(''),
      address: new FormControl(''),
      address2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      mlsAffiliation: new FormControl(''),
      preferredTms: new FormControl(''),
      agreeTandC: new FormControl(false, [Validators.requiredTrue]),
      membershipPlan: new FormControl(''),
    });

    this.realtorSignUpForm.get('licenseExpiration')?.valueChanges.subscribe(() => {
      this.isExpirationDateValid();
    });
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (group: FormGroup) => {
      const control = group.controls[controlName];
      const matchingControl = group.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      }
      else {
        matchingControl.setErrors(null);
      }
    }
  }

  get form() { return this.realtorSignUpForm.controls; }

  createDropIn() {
    const url = this.environmentService.getIA9LightningUrl() + 'registration/getpaymenttoken';
    this.professionalService.getClientAuthToken(url, this.xClientId).subscribe((response) => {
      if (response?.data) {
        dropin.create({
          authorization: response.data,
          container: document.getElementById('dropin-container')
        }).then((dropinInstance) => {
          this.dropInInstance = dropinInstance;
          this.spinner.hide();
        }).catch((error) => { console.log(error) });
      }
    }, (error) => {
      console.log(error);
    });
  }

  retrievePlans() {
    const url = this.environmentService.getIA9LightningUrl() + 'payment/realtor/plans?code=' + this.environmentService.getIA9LightningKey();
    this.professionalService.getMembershipPlansRealtors(url, this.xClientId).subscribe((response) => {
      if (response?.data) {
        this.membershipRealtorsPlans = response?.data
      }
    }, (error) => {
    })
  }

  radioChecked(data, i) {
    if(data.price !== 'Free'){
      this.spinner.show();
      this.createDropIn();
    }else{
      if(this.dropInInstance){
        this.dropInInstance.teardown(function(err) {
            if (err) { console.error('An error occurred during teardown:', err); }
        });
      }
    }

    this.membershipRealtorsPlans.forEach((item: any) => {
      if (item.uid !== data.uid) {
        item.selected = false;
      } else {
        item.selected = true;
      }
    })
    this.realtorSignUpForm.get("membershipPlan").patchValue(data.code);
    this.selectedPlan = data.price;
  }

  onSubmit() {
    this.submitted = true;
    this.isExpirationDateValid();
    if (this.realtorSignUpForm.invalid) {
      return;
    }

    this.registerUser();
  }

  isExpirationDateValid() {
    const expDate = new Date(this.realtorSignUpForm.get('licenseExpiration').value);
    if (expDate.getTime() < new Date(this.tomorrowDate).getTime()) {
      this.realtorSignUpForm.get('licenseExpiration').setErrors({ min: true });
    }
  }

  registerUser(payload?) {
    const realtorPayload = new RealtorRegModel();
    realtorPayload.firstName = this.realtorSignUpForm.get('firstName').value;
    realtorPayload.lastName = this.realtorSignUpForm.get('lastName').value;
    realtorPayload.phone = this.realtorSignUpForm.get('mobileNumber').value;
    realtorPayload.email = this.realtorSignUpForm.get('emailId').value;
    realtorPayload.type = 1;
    realtorPayload.companyName = this.realtorSignUpForm.get('companyName').value;
    realtorPayload.companyUrl = this.realtorSignUpForm.get('companyUrl').value;
    realtorPayload.licenseNumber = this.realtorSignUpForm.get('licenseNumber').value;
    realtorPayload.licenseState = this.realtorSignUpForm.get('licenseState').value;
    realtorPayload.licenseExpiration = this.realtorSignUpForm.get('licenseExpiration').value;
    realtorPayload.mlsAffiliation = this.realtorSignUpForm.get('mlsAffiliation').value;
    realtorPayload.preferredTms =  this.realtorSignUpForm.get('preferredTms').value[0];
    realtorPayload.address1 = this.realtorSignUpForm.get('address').value;
    realtorPayload.address2 = this.realtorSignUpForm.get('address2').value;
    realtorPayload.city = this.realtorSignUpForm.get('city').value;
    realtorPayload.state = this.realtorSignUpForm.get('state').value;
    realtorPayload.zip = this.realtorSignUpForm.get('zip').value;
    realtorPayload.paymentMethodNonce = '';
    realtorPayload.MembershipPlan = this.realtorSignUpForm.get('membershipPlan').value;
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'registration/professional/enroll?code=' + this.environmentService.getIA9LightningKey();
    this.professionalService.registerProfessional(url, realtorPayload, this.xClientId).subscribe(response => {
      this.redirectToSuccess();
    }, error => {
      console.log(error);
      this.spinner.hide();
      this.errorMsg = error.error.description;
      document.getElementById('openRegFailedBtn').click();
    });
  }

  registerUserWithHubspot(realtorPayload: RealtorRegModel) {
    const hubSpotcontact = new HubspotContactModel;
    hubSpotcontact.company = realtorPayload.companyName;
    hubSpotcontact.companyUrl = realtorPayload.companyUrl;
    hubSpotcontact.firstname = realtorPayload.firstName;
    hubSpotcontact.lastname = realtorPayload.lastName;
    hubSpotcontact.bt_plan = "Domii Pro Trial";
    hubSpotcontact.mobilephone = realtorPayload.phone;
    hubSpotcontact.email = realtorPayload.email;
    hubSpotcontact.bt_plan_id = realtorPayload.MembershipPlan;
    hubSpotcontact.address = realtorPayload.address1;
    hubSpotcontact.address2 = realtorPayload.address2;
    hubSpotcontact.city = realtorPayload.city;
    hubSpotcontact.state = realtorPayload.state;
    hubSpotcontact.zip_code = realtorPayload.zip;
    hubSpotcontact.professional_type = realtorPayload.type;
    hubSpotcontact.mls_affliation = realtorPayload.mlsAffiliation;
    hubSpotcontact.preferredTms = realtorPayload.preferredTms;
    this.spinner.show();
    this.hubSpotService.createContact(hubSpotcontact, '/realtorsignup').subscribe(response => {
      console.log("User registered in HS");
      this.redirectToSuccess();
    },
      error => {
        console.log(error);
        this.spinner.hide();
        for (let index = 0; index < error.error.errors.length; index++) {
          if (error.error.errors[index].errorType === "INVALID_EMAIL") {
            this.errorMsg = 'Invalid email address';
            document.getElementById('openRegFailedBtn').click();
          }
        };
      });
  }

  redirectToHome() {
    setTimeout(() => {
      if ($('#regSuccess').hasClass('show')) {
        document.getElementById('openRegSuccessBtn').click();
      }
      this.token = sessionStorage.getItem('msal.idtoken');
    const paramToken = this.activatedRoute.snapshot.queryParamMap.get("token");
    if (paramToken !== null) {
      this.token = paramToken;
      console.log("token",this.token);
      sessionStorage.setItem('msal.idtoken', this.token);
    }
      this.router.navigate(['/dashboard']);
    }, 2000);
  }

  redirectToSuccess() {
    setTimeout(() => {
      document.getElementById('openSignupSuccessBtn').click();
      this.spinner.hide();
    }, 2000);
  }

  openTerms(event, type) {
    event.preventDefault();
    document.getElementById('openTermsConditionsBtn').click();
    (document.getElementById('t&CLogo') as HTMLImageElement).src = CommonConstants.imageBasePath + this.environmentService.getCompanyLogo();
    let url = '';
    if (type == 'TC') {
      this.tcHeader = 'Terms and Conditions';
      url = this.environmentService.getEULAUrl();
    }
    else if (type == 'PP') {
      this.tcHeader = 'Privacy Policy';
      url = this.environmentService.getPrivacyUrl();
    }
    this.tcURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  login() {
    this.loginService.login();
  }

  isNumber(event) {
    const regex = new RegExp('[0-9]+');
    return regex.test(event.key);
  }

  validateEmail(event: any) {
    const url = this.environmentService.getIA9LightningUrl() + 'registration/validate/email/' + event.target.value + '?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.professionalService.getValidEmail(url, this.xClientId).subscribe((response) => {
      this.displayEmailError = false;
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      if (error.error.statusCode === 8002) {
        this.displayEmailError = true;
        this.errorMessage = 'An account with email address already exists.';
      }
    })
  }

  redirectToLogin() {
    this.loginService.login();
    this.displayEmailError = false;
  }

  onChange(event) {
    this.spinner.show();
    this.resetCompanyInfo();
    const email = this.realtorSignUpForm.get("emailId").value;
    const mlsAffiliates = event.target.value;
    const url = this.environmentService.getIA9LightningUrl() + 'agent/' + event.target.value +'/'+ email+'?code=' + this.environmentService.getIA9LightningKey();
    if(email.length>0 && mlsAffiliates.length>0){
      this.realtorService.getCompanyDetails(url, this.xClientId).subscribe(
        response => {
          const companyInfo = response['data'];
          this.realtorSignUpForm.get("companyName").setValue(companyInfo.companyName);
          this.realtorSignUpForm.get("companyUrl").setValue(companyInfo.companyUrl);
          this.realtorSignUpForm.get("address").setValue(companyInfo.address1);
          this.realtorSignUpForm.get("address2").setValue(companyInfo.address2);
          this.realtorSignUpForm.get("city").setValue(companyInfo.city);
          this.realtorSignUpForm.get("state").setValue(companyInfo.state);
          this.realtorSignUpForm.get("zip").setValue(companyInfo.zip);
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
        });
    }
  }

  resetCompanyInfo(){
    this.realtorSignUpForm.get("companyName").reset("");
    this.realtorSignUpForm.get("companyUrl").reset("");
    this.realtorSignUpForm.get("address").reset("");
    this.realtorSignUpForm.get("address2").reset("");
    this.realtorSignUpForm.get("city").reset("");
    this.realtorSignUpForm.get("state").reset("");
    this.realtorSignUpForm.get("zip").reset("");
  }

	openAppStore(){
		window.open(this.iOSStoreUrl);
	}

	openPlayStore(){
		window.open(this.androidStoreUrl);
	}

}
