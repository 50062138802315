import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as dropin from 'braintree-web-drop-in';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { RealtorService } from 'src/app/services/realtors/realtor.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonConstants } from 'src/app/constants/commonConstants';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PaymentModel, RealtorPlan } from 'src/app/models/PaymentModel';

@Component({
	selector: 'app-upgrade-payment',
	templateUrl: './upgrade-payment.component.html',
	styleUrls: ['./upgrade-payment.component.scss'],
	providers: [ConfirmationService, MessageService]

})
export class UpgradePaymentComponent implements OnInit {

	upgradeForm: FormGroup;
	submitted = false;
	xClientId: string;
	// realtorPlans: [] = [] as any;
	realtorPlans: RealtorPlan[];
	dropInInstance: dropin.DropinModel;
	validateCode: string = '';
	displayErrorPopup: boolean = false;
	errorMessage: string = '';
	errorHasButton: boolean = false;
	errorPromoCodeMessage: string = '';
	validPromoCodeMessage: string = '';
	userProfile: any;
	realtorPaymentDetails: any;
	realtorFN: string = '';
	_payload = {
		"PaymentMethodToken": '',
		"MembershipPlanCode": '',
		"MembershipPlanId": '',
		"Code": '',
		"promoCode": '',
	};
	termsAndConditons: any;
	tcURL: SafeResourceUrl;
	tcHeader: string = '';
	price: string;
	selectedPlan: string = '';
	isBraintreeInitialized: boolean = false;
	uniquePlanCategories: string[] = [];
	selectedCategory: string = '';

	constructor(
		private formBuilder: FormBuilder,
		private realtorService: RealtorService,
		private professionalService: ProfessionalService,
		private environmentService: EnvironmentService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private route: ActivatedRoute,
		private sanitizer: DomSanitizer,
	) {
		this.validateCode = this.route.snapshot.params['code'];
		this._payload.Code = this.validateCode;
		this.xClientId = this.environmentService.getXClientId();
	}

	ngOnInit(): void {
		this.buildUpgradeForm();
		this.validate();
	}
	
	selectCategory(category: string): void {
		this.selectedCategory = category;
	}

	buildUpgradeForm() {
		this.upgradeForm = this.formBuilder.group({
			agreeTandC: new FormControl(false, [Validators.requiredTrue]),
			membershipPlan: new FormControl('', [Validators.required]),
			promocode: new FormControl(''),
		});
	}

	validate() {
		let url = this.environmentService.getIA9LightningUrl() + 'V2/payment/update/' + this.validateCode + '?code=' + this.environmentService.getIA9LightningKey();
		this.spinner.show();
		this.realtorService.validateCode(url, this.xClientId).subscribe((response: any) => {
			this.retrievePlans();
			this.spinner.hide();
		},
			(error) => {
				this.spinner.hide();
				this.errorMessage = error.error.description;
				if (error.error.statusCode === 6001) {
					this.errorMessage = "Account upgrade already completed.<br />You may now close this page.";
				}
				if (error.error.statusCode === 6002) {
					this.errorMessage = "Pending Verification of license status within 48 Business hours.<br /> We will send you an email once approved, thank you for your patience.";
				}
				if (error.error.statusCode === 6003) {
					this.errorMessage = "Your profile is incomplete, please update your profile information <a href='https://account-staging.domii.io/profile'>here</a>.";
				}
				document.getElementById('failedBtn').click();
			});
	}

	validatePromoCode(promoCode) {
		this.errorPromoCodeMessage = "";
		this.validPromoCodeMessage = "";
		this._payload.promoCode = "";
		let url = `${this.environmentService.getIA9LightningUrl()}payment/promo/plans?code=${this.environmentService.getIA9LightningKey()}&pCode=${promoCode}`;
		this.spinner.show();
		this.realtorService.validateCode(url, this.xClientId).subscribe((response: any) => {
			console.log("VALIDS");
			this.validPromoCodeMessage = response.description;
			this._payload.promoCode = this.upgradeForm.get('promocode').value;
			this.spinner.hide();
		},
			(error) => {
				this.spinner.hide();
				this.errorPromoCodeMessage = error.error.description;
			});
	}

	onApplyPromoCode(){
		const promocode = this.upgradeForm.get('promocode').value;
		if(promocode === ""){
			this.errorPromoCodeMessage = "Please enter promo code";
		}else{
			this.validatePromoCode(this.upgradeForm.get('promocode').value);
		}
	}

	get form() { return this.upgradeForm.controls; }

	createDropIn() {
		const url = this.environmentService.getIA9LightningUrl() + 'registration/getpaymenttoken';
		this.professionalService.getClientAuthToken(url, this.xClientId).subscribe((response) => {
			if (response?.data) {
				dropin.create({
					authorization: response.data,
					container: document.getElementById('dropin-container')
				}).then((dropinInstance) => {
					this.dropInInstance = dropinInstance;
					this.isBraintreeInitialized = true;
					this.spinner.hide();
				}).catch((error) => { console.log(error) });
			}
		}, (error) => {
			this.spinner.hide();
			this.errorMessage = error.error.description;
			if (error.error.statusCode === 6001) {
				this.errorMessage = "Account upgrade already completed.<br />You may now close this page.";
			}
			if (error.error.statusCode === 6002) {
				this.errorMessage = "Pending Verification of license status within 48 Business hours.<br /> We will send you an email once approved, thank you for your patience.";
			}
			if (error.error.statusCode === 6003) {
				this.errorMessage = "Your profile is incomplete, please update your profile information <a href='https://account-staging.domii.io/profile'>here</a>.";
			}
			document.getElementById('failedBtn').click();
		});
	}

	retrievePlans() {
		const url = this.environmentService.getIA9LightningUrl() + 'payment/realtorupgrade/plans?pcode=' + this._payload.Code + '&code=' + this.environmentService.getIA9LightningKey();
		this.spinner.show();
		this.professionalService.getMembershipPlansRealtors(url, this.xClientId).subscribe((response) => {
			if (response?.data) {
				this.realtorPlans = response?.data;
				this.getPlanData();
			}
		}, (error) => {
			this.spinner.hide();
			this.errorMessage = error.error.description;
			if (error.error.statusCode === 6001) {
				this.errorMessage = "Account upgrade already completed.<br />You may now close this page.";
			}
			if (error.error.statusCode === 6002) {
				this.errorMessage = "Pending Verification of license status within 48 Business hours.<br /> We will send you an email once approved, thank you for your patience.";
			}
			if (error.error.statusCode === 6003) {
				this.errorMessage = "Your profile is incomplete, please update your profile information <a href='https://account-staging.domii.io/profile'>here</a>.";
			}
			document.getElementById('failedBtn').click();
		});
	}

	radioChecked(data, i) {
		if(this.isBraintreeInitialized){
			this.dropInInstance.teardown(function (err) {
				if (err) { console.error('An error occurred during teardown:', err); }
			});
		}

		if (data.price !== 'Free') {
			this.spinner.show();
			this.createDropIn();
		}else{
			this.isBraintreeInitialized = false;
		}

		this.realtorPlans.forEach((item: any) => {
			if (item.uid !== data.uid) {
				item.selected = false;
			} else {
				item.selected = true;
			}
		});

		this.upgradeForm.get("membershipPlan").patchValue(data.code);
		this._payload.MembershipPlanCode = data.code;
		this._payload.MembershipPlanId = data.uid;
		this.selectedPlan = data.price;
	}

	onSubmit() {
		this.submitted = true;
		if (this.upgradeForm.invalid) {
			return;
		}

		const promoCode = this.upgradeForm.get('promocode').value;
		if(this._payload.promoCode === "" && promoCode !== ""){
			let url = `${this.environmentService.getIA9LightningUrl()}payment/promo/plans?code=${this.environmentService.getIA9LightningKey()}&pCode=${promoCode}`;
			this.spinner.show();	
			this.errorPromoCodeMessage = "";
			this.validPromoCodeMessage = "";
			this._payload.promoCode = "";
			this.realtorService.validateCode(url, this.xClientId).subscribe((response: any) => {
				this.validPromoCodeMessage = response.description;
				this._payload.promoCode = promoCode;
				this.spinner.hide();
				if(this.selectedPlan !== 'Free'){
					this.dropInInstance.requestPaymentMethod().then((payload) => {
						this.upgradeAccount(payload);
					}).catch((error) => { console.log(error) });
				}else{
					this.upgradeAccount();
				}
			},
			(error) => {
				this.spinner.hide();
				this.errorPromoCodeMessage = error.error.description;
			});
		}else{
			if(this.errorPromoCodeMessage === ""){
	
				if(this.selectedPlan !== 'Free'){
					this.dropInInstance.requestPaymentMethod().then((payload) => {
						this.upgradeAccount(payload);
					}).catch((error) => { console.log(error) });
				}else{
					this.upgradeAccount();
				}
			}
		}

	}

	upgradeAccount(payload?) {
		this._payload.PaymentMethodToken = '';
		if(this.selectedPlan !== 'Free'){
			this._payload.PaymentMethodToken = payload.nonce;
		}

		const uploadPayload = new PaymentModel();
		uploadPayload.paymentMethodToken = this._payload.PaymentMethodToken;
		uploadPayload.membershipPlanCode = this._payload.MembershipPlanCode;
		uploadPayload.membershipPlanId = this._payload.MembershipPlanId;
		uploadPayload.code = this._payload.Code;
		uploadPayload.promoCode = this._payload.promoCode;

		const url = this.environmentService.getIA9LightningUrl() + 'V2/payment/update?code=' + this.environmentService.getIA9LightningKey();
		this.spinner.show();
		this.realtorService.realtorPayment(url, uploadPayload, this.xClientId).subscribe((response) => {
			setTimeout(() => {
				document.getElementById('successBtn').click();
				this.spinner.hide();
			}, 2000);
		},
			(error) => {
				this.displayErrorPopup = true;
				this.spinner.hide();
				this.errorMessage = error.error.description;
				if (error.error.statusCode === 10005) {
					this.errorHasButton = true;
				}
				if (error.error.statusCode === 6001) {
					this.errorMessage = "Account upgrade already completed.<br />You may now close this page.";
				}
				if (error.error.statusCode === 6002) {
					this.errorMessage = "Pending Verification of license status within 48 Business hours.<br /> We will send you an email once approved, thank you for your patience.";
				}
				if (error.error.statusCode === 6003) {
					this.errorMessage = "Your profile is incomplete, please update your profile information <a href='https://account-staging.domii.io/profile'>here</a>.";
				}
				document.getElementById('failedBtn').click();
			});
	}

	openTerms(event, type) {
		event.preventDefault();
		document.getElementById('openTermsConditionsBtn').click();
		(document.getElementById('t&CLogo') as HTMLImageElement).src = CommonConstants.imageBasePath + this.environmentService.getCompanyLogo();
		let url = '';
		if (type == 'TC') {
			this.tcHeader = 'Terms and Conditions';
			url = this.environmentService.getEULAUrl();
		}
		else if (type == 'PP') {
			this.tcHeader = 'Privacy Policy';
			url = this.environmentService.getPrivacyUrl();
		}
		this.tcURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}

	getPlanData(){
		this.uniquePlanCategories = [...new Set(this.realtorPlans.map(plan => plan.planCategoryDescription))].filter(Boolean).sort();
		this.selectedCategory = this.uniquePlanCategories[0];
	}
}