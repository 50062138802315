<app-header></app-header>
<!-- <app-spinner *ngIf="loading"></app-spinner> -->
<app-toasts *ngIf="toastVisible" [toastType]="toastType" [content]="toastMessage" [(visible)]="toastVisible"
    (closeToast)="closeToast()">
</app-toasts>
<div class="back_btn">
    <a routerLink="/dashboard">
        <img src="../../../assets/images/back_arrow.svg" alt="">
    </a>
</div>
<div class="mainContainer">
    <!-- <app-plan-ending-alert *ngIf="showPlanAlert"></app-plan-ending-alert> -->
    <div class="card noBorder footerMargin" style="border: 16px solid #ffeff1; padding: 1%;">
        <div class="card-body">
            <div class="card-title col-xs-12">
                <h1>Personal Info</h1>
            </div>
            <form class="profileForm" (ngSubmit)="onSubmit()" #profileForm="ngForm">
                <div class="row justifyCenter" [style.background-color]="headerbgColor" *ngIf="realtorProfile">
                    <input type="file" #file id="profileImage" hidden (change)="onFileChange($event, 'profileImg')"
                        accept="image/jpg,image/jpeg">
                    <div class="imageContainer">
                        <img style="cursor: pointer;" [src]="imageSrc" *ngIf="imageSrc" class="previewImg">
                        <img style="cursor: pointer;" src="../../../assets/images/profileImg.svg" *ngIf="!imageSrc"
                            class="previewImg">
                        <img style="cursor: pointer;" src="../../../assets/images/camera.svg" class="uploadImg"
                            (click)="selectImage()">
                        <img style="cursor: pointer;" src="../../../assets/images/preview.svg" class="uploadImg2"
                            (click)="showDialog()">
                    </div>
                    <div class="headerText">
                        <p class="p1">{{listingInfo.businessName}} & Associates</p>
                        <p class="p2">{{listingInfo.agentName}}, Realtor</p>
                        <div class="allImages">
                            <div class="telIcon">
                                <span>
                                    <a href="{{'tel:' + listingInfo.agentPhoneNumber}}"> <i style="font-size: 24px;"
                                            class="pi pi-phone product-category-icon"></i></a>
                                </span>
                            </div>
                            <div class="mailIcon">
                                <span>
                                    <a href="{{'mailto:' + listingInfo.agentEmail }}"><i style="font-size: 24px;"
                                            class="pi pi-envelope product-category-icon"></i></a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justifyCenter" style="background-color: #fdf7f8;
                box-shadow: 0px 0px 10px #0000001a;
                border-radius: 6px;
                border: none;" *ngIf="!realtorProfile">
                    <input type="file" #file id="profileImage" hidden (change)="onFileChange($event, 'profileImg')"
                        accept="image/jpg,image/jpeg">
                    <div class="imageContainer">
                        <img [src]="imageSrc" *ngIf="imageSrc" class="previewImg">
                        <img src="../../../assets/images/profileImg.svg" *ngIf="!imageSrc" class="previewImg">
                        <img src="../../../assets/images/camera.svg" class="uploadImg" (click)="selectImage()">
                    </div>
                </div>
                <div class="row">
                    <span class="form-group col-md-5 col-xs-12 selecte-file">Select jpg, jpeg files only</span>
                </div>
                <div class="row">
                    <div class="form-group col-md-5 col-xs-12">
                        <label for="firstName">First Name</label>
                        <input type="text" class="form-control" id="firstName" placeholder="Enter First Name"
                            [ngClass]="{'is-invalid': submitted && firstName.errors}" required disabled
                            [(ngModel)]="userProfile.firstName" name="firstName" #firstName="ngModel">
                        <div class="invalid-feedback" *ngIf="submitted && firstName.errors?.required">
                            <span>Please enter First Name</span>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="form-group col-md-5 col-xs-12">
                        <label for="lastName">Last Name</label>
                        <input type="text" class="form-control" id="lastName" placeholder="Enter Last Name"
                            [ngClass]="{'is-invalid': submitted && lastName.errors}" required disabled
                            [(ngModel)]="userProfile.lastName" name="lastName" #lastName="ngModel">
                        <div class="invalid-feedback" *ngIf="submitted && lastName.errors?.required">
                            <span>Please enter Last Name</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-5 col-xs-12">
                        <label for="mobileNumber">Mobile Number</label>
                        <input type="number" class="form-control" id="mobileNumber" placeholder="Enter Mobile Number"
                            [ngClass]="{'is-invalid': submitted && mobileNumber.errors}" required
                            [(ngModel)]="userProfile.mobileNumber" name="mobileNumber" #mobileNumber="ngModel">
                        <div class="invalid-feedback" *ngIf="submitted && mobileNumber.errors?.required">
                            <span>Please enter Mobile Number</span>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="form-group col-md-5 col-xs-12">
                        <label for="emailId">Email ID</label>
                        <input type="email" class="form-control" id="emailId" placeholder="Enter Email ID"
                            [ngClass]="{'is-invalid': (submitted || emailId.dirty) && emailId.errors}" required disabled
                            [(ngModel)]="userProfile.emailId" name="emailId" #emailId="ngModel"
                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">
                        <div class="invalid-feedback" *ngIf="(submitted || emailId.dirty) && emailId.errors">
                            <span *ngIf="emailId.errors.required">Please enter Email</span>
                            <span *ngIf="emailId.errors.pattern">Please enter Valid Email</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-5 col-xs-12">
                        <label for="companyName">Company Name</label>
                        <input type="text" class="form-control" id="companyName" placeholder="Enter Company Name"
                            [ngClass]="{'is-invalid': submitted && companyName.errors}" required
                            [(ngModel)]="userProfile.companyName" name="companyName" #companyName="ngModel">
                        <div class="invalid-feedback" *ngIf="submitted && companyName.errors?.required">
                            <span>Please enter Company Name</span>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="form-group col-md-5 col-xs-12">
                        <label for="companyUrl">Company/Personal Website URL</label>
                        <input type="text" class="form-control" id="companyUrl" placeholder="Enter Company/Personal Website URL"
                            [ngClass]="{'is-invalid': (submitted || companyUrl.dirty) && companyUrl.errors}" [pattern]="urlPattern"
                            [(ngModel)]="userProfile.companyUrl" name="companyUrl" #companyUrl="ngModel">
                        <div class="invalid-feedback" *ngIf="(submitted || companyUrl.dirty) && companyUrl.errors">
                            <span>Please enter Company/Personal Website URL</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-5 col-xs-12">
                        <label for="address1">Address1</label>
                        <input type="text" class="form-control" id="address1" placeholder="Enter Address"
                            [ngClass]="{'is-invalid': submitted && address1.errors}" required
                            [(ngModel)]="userProfile.address1" name="address1" #address1="ngModel">
                        <div class="invalid-feedback" *ngIf="submitted && address1.errors?.required">
                            <span>Please enter Address</span>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="form-group col-md-5 col-xs-12">
                        <label for="address2">Address2</label>
                        <input type="text" class="form-control" id="address2" placeholder="Enter address2"
                            [(ngModel)]="userProfile.address2" name="address2" #address2="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-5 col-xs-12">
                        <label for="city">City</label>
                        <input type="text" class="form-control" id="city" placeholder="Enter City"
                            [ngClass]="{'is-invalid': submitted && city.errors}" required [(ngModel)]="userProfile.city"
                            name="city" #city="ngModel">
                        <div class="invalid-feedback" *ngIf="submitted && city.errors?.required">
                            <span>Please enter City</span>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="form-group col-md-5 col-xs-12">
                        <label for="state">State</label>
                        <select class="form-control" [(ngModel)]="userProfile.state" id="state" name="state"
                            #state="ngModel" [ngClass]="{'is-invalid': submitted && state.errors}" required>
                            <option value="" class="option">Select State</option>
                            <option *ngFor="let state of states" class="option" value="{{state.value}}">{{state.name}}
                            </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submitted && state.errors?.required">
                            <span>Please select State</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-5 col-xs-12">
                        <label for="zip">Zip Code</label>
                        <input type="text" class="form-control" id="zip" placeholder="Enter Zip Code"
                            [ngClass]="{'is-invalid': submitted && zip.errors}" required [(ngModel)]="userProfile.zip"
                            name="zip" #zip="ngModel">
                        <div class="invalid-feedback" *ngIf="submitted && zip.errors?.required">
                            <span>Please enter Zip Code</span>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="form-group col-md-5 col-xs-12" *ngIf="realtorProfile">
                        <label for="mlsAffiliation">Primary MLS Affiliation</label>
                        <select class="form-control" [(ngModel)]="userProfile.mlsAffiliation" id="mlsAffiliation"
                            name="mlsAffiliation" #mlsAffiliation="ngModel"
                            [ngClass]="{'is-invalid': submitted && mlsAffiliation.errors}" required>
                            <option value="" class="option">Select</option>
                            <option *ngFor="let mls of mlsAffiliates" class="option" value="{{mls.vendorName}}">
                                {{mls.vendorName}}</option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submitted && mlsAffiliation.errors?.required">
                            <span>Please select MLS Affiliation</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-5 col-xs-12">
                        <label for="preferredTms">Document Management</label>
                        <select class="form-control" [(ngModel)]="userProfile.preferredTms" id="preferredTms"
                            name="preferredTms" #preferredTms="ngModel"
                            [ngClass]="{'is-invalid': submitted && preferredTms.errors}" required>
                            <option value="" class="option">Select a Document Manager</option>
                            <option *ngFor="let tms of tmsArray" class="option" value="{{tms.viewValue}}">
                                {{tms.viewValue}}</option>
                        </select>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="form-group col-md-5 col-xs-12" *ngIf="!realtorProfile">
                        <label for="licenseNumber">License Info</label>
                        <input type="text" class="form-control" id="licenseNumber" placeholder="Enter License Number"
                            [(ngModel)]="userProfile.licenseNumber" name="licenseNumber" #licenseNumber="ngModel"
                            (keypress)="isNumber($event)">
                    </div>
                </div>
                <br><br>
                <div class="row" *ngIf="realtorProfile" style="    box-shadow: 0px 1px 4px #15223214;
                border-radius: 6px;
                background: #fdf7f8 0% 0% no-repeat padding-box;
                padding: 10px 20px;">
                    <div class="form-group col-md-5 col-xs-12">
                        <span class="receive-leads">From what location do you want to receive leads?</span>
                        <div class="martop10px">
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" name="locationOption" id="gpsLocation"
                                    [value]=0 [(ngModel)]="userProfile.locationOption" #locationOption="ngModel">
                                <label class="custom-control-label" for="gpsLocation">
                                    GPS (the location of your phone)
                                </label>
                            </div>
                            <div class="custom-control custom-radio" style="margin-top: 5px;">
                                <input class="custom-control-input" type="radio" name="locationOption"
                                    id="physicalAddress" [value]=1 [(ngModel)]="userProfile.locationOption"
                                    #locationOption="ngModel">
                                <label class="custom-control-label" for="physicalAddress">
                                    Physical Address (based on the address entered)
                                </label>
                            </div>
                            <div class="custom-control custom-radio" style="margin-top: 5px;">
                                <input class="custom-control-input" type="radio" name="locationOption" id="zipLocation"
                                    [value]=2 [(ngModel)]="userProfile.locationOption" #locationOption="ngModel">
                                <label class="custom-control-label" for="zipLocation">
                                    ZIP (based on the Zip code entered)
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="form-group col-md-5 col-xs-12">
                        <span class="receive-leads" for="companyLogo">Company Logo</span>
                        <div class="clsection">
                            <div>
                                <img [src]="userProfile.companyPhoto" *ngIf="userProfile.companyPhoto" class="cmImg">
                                <img src="../../../assets/images/profileImg.svg" *ngIf="!userProfile.companyPhoto"
                                    class="cmImg">
                            </div>
                            <div>
                                <input type="file" #companyLogo id="companyLogo" hidden
                                    (change)="onFileChange($event, 'companyLogo')" accept="image/jpg">
                                <div>
                                    <button type="button" class="chooseImgBtn" (click)="selectCompanyPhoto()">
                                        <img src="../../../assets/images/plus-circle.svg" alt="add photo">
                                        <span class="chooseImgTxt">Choose Image</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br><br>

                <div class="row" class="licensesSection" *ngIf="realtorProfile">
                    <div class="licensesTitle">Licenses</div>
                    <div class="row col-md-12 col-xs-12">
                        <div class="col-md-6 col-xs-12" *ngFor="let license of userProfile.licenses; let i = index">
                            <div class="col-md-12 col-xs-12" style="text-align: end;
                            margin-top: 10px;">
                                <img src="../../../assets/images/close.svg" alt="remove License"
                                    (click)="removeLicense(i)" style="cursor: pointer;">
                            </div>
                            <div class="col-md-12 col-xs-12" style="border-radius: 6px;
                            background: #f2f2f2 0% 0% no-repeat padding-box;
                            box-shadow: 0px 1px 4px #15223214;">
                                <div class="row martop10px">
                                    <div class="form-group col-md-6 col-xs-12">
                                        <label for="licenseNumber">Number</label>
                                        <input type="text" class="form-control" id="{{'licenseNumber'+i}}"
                                            name="{{'licenseNumber'+i}}" placeholder="Enter License Number"
                                            #licenseNumber="ngModel"
                                            [ngClass]="{'is-invalid': submitted && licenseNumber.errors}"
                                            [(ngModel)]="license.LicenseNumber" required (keypress)="isNumber($event)">
                                        <div class="invalid-feedback"
                                            *ngIf="submitted && licenseNumber.errors?.required">
                                            <span>Please enter License Number</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <label for="licenseState">State</label>
                                        <select class="form-control" id="{{'licenseState'+i}}" required
                                            [(ngModel)]="license.LicenseState"
                                            [ngClass]="{'is-invalid': submitted && licenseState.errors}"
                                            #licenseState="ngModel" name="{{'licenseState'+i}}">
                                            <option value="" class="option">Select State</option>
                                            <option *ngFor="let state of states" class="option" value="{{state.value}}">
                                                {{state.name}}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="submitted && licenseState.errors?.required">
                                            <span>Please select State</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6 col-xs-12">
                                        <label for="licenseExpiration">Expiry Date</label>
                                        <input type="date" class="form-control" [(ngModel)]="license.LicenseExpiration"
                                            required id="{{'licenseExpiration'+i}}" placeholder="YYYY-MM-DD"
                                            #licenseExpiration="ngModel" name="{{'licenseExpiration'+i}}"
                                            [min]="tomorrowDate" (change)="isExpirationDateValid($event)">
                                        <div class="invalid-feedback"
                                            *ngIf="(submitted || licenseExpiration.dirty) && licenseExpiration.errors">
                                            <span *ngIf="licenseExpiration.errors.required">Please enter Expiry
                                                Date</span>
                                            <span *ngIf="licenseExpiration.errors.min">Expiration Date has passed</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <label for="Status">Status</label>
                                        <input type="text" class="form-control" id="{{'Status'+i}}" placeholder="Status" disabled [(ngModel)]="license.Status" name="Status" #Status="ngModel">
                                    </div>
                                    <!-- <div class="form-group col-md-6 col-xs-12">
                                        <label for="licenseImg">Upload</label>
                                        <input type="file" #licenseImg id="licenseImg" hidden
                                            (change)="onFileChange($event, 'licenseImg')" accept="image/jpg">
                                        <div>
                                            <button type="button" class="chooseImgBtn" style="padding: 0.5rem"
                                                (click)="selectLicenseImg()">
                                                <img src="../../../assets/images/plus-circle.svg" alt="upload license">
                                                <span class="chooseImgTxt">Choose Image</span>
                                            </button>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="form-group col-md-5 col-xs-12 padnull martop20px" *ngIf="realtorProfile">
                        <button type="button" class="chooseImgBtn" (click)="addLicense()">
                            <img src="../../../assets/images/plus-circle.svg" alt="add license">
                            <span class="chooseImgTxt">Add Another License</span>
                        </button>
                    </div>
                </div>
                <div class="flexDspl martop20px">
                    <button type="submit" class="btn submitButton">Save</button>
                    <span class="formErrMsg" *ngIf="submitted && profileForm.form.invalid">*Enter All the Fields</span>
                </div>
            </form>
        </div>
    </div>
</div>


<!-- Preview Header -->
<section class="add-template-card">
    <p-dialog header="Customize Your Header" [modal]="true" [(visible)]="displayPreviewHeader"
        [style]="{width: '60%', padding: '10px'}" [baseZIndex]="10000" position="top" [draggable]="false"
        [resizable]="true" [breakpoints]="{'960px': '100vw'}">
        <p-card header="" subheader="" styleClass="p-card-shadow">
            <ng-template pTemplate="header">
                <input type="text" class="form-control" [(ngModel)]="color" id="headerColorHex"
                    placeholder="Select Background Color" [(colorPicker)]="color" [style.background]="color"
                    [value]="color" (colorPickerChange)="pickColour($event)">
            </ng-template>
            <ng-template pTemplate="content">
                <div class="row justifyCenter" [style.background-color]="headerbgColor">
                    <input type="file" #file id="profileImage" hidden accept="image/jpg,image/jpeg">
                    <div class="imageContainer">
                        <img style="cursor: pointer;" [src]="imageSrc" *ngIf="imageSrc" class="previewImg">
                        <img style="cursor: pointer;" src="../../../assets/images/profileImg.svg" *ngIf="!imageSrc"
                            class="previewImg">
                        <img style="cursor: pointer;" src="../../../assets/images/camera.svg" class="uploadImg">
                        <img style="cursor: pointer;" src="../../../assets/images/preview.svg" class="uploadImg2">
                    </div>
                    <div class="headerText">
                        <p class="p1">{{listingInfo.businessName}} & Associates</p>
                        <p class="p2">{{listingInfo.agentName}}, Realtor</p>
                        <div class="allImages">
                            <div class="telIcon">
                                <span>
                                    <i style="font-size: 24px;color: #f75a72;"
                                        class="pi pi-phone product-category-icon"></i>
                                </span>
                            </div>
                            <div class="mailIcon">
                                <span>
                                    <i style="font-size: 24px;color: #f75a72;"
                                        class="pi pi-envelope product-category-icon"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <section class="footer-section">
                    <div class="footer-section-1">
                        <div class="bottom-image">
                            <button (click)="updateBGColor()" style="margin-right: 16px;" pButton label="Submit"
                                class="p-button-text">
                            </button>
                            <button (click)="cancel()" pButton label="Cancel" class="p-button-text">
                            </button>
                        </div>
                    </div>
                </section>
            </ng-template>
        </p-card>
    </p-dialog>
</section>
<app-footer></app-footer>
