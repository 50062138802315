import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { RealtorService } from 'src/app/services/realtors/realtor.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-open-house-signin-summary',
  templateUrl: './open-house-signin-summary.component.html',
  styleUrls: ['./open-house-signin-summary.component.scss']
})
export class OpenHouseSigninSummaryComponent implements OnInit {

  xClientId: string;
  listingInfo: any[] = [];
  attendeesDetails: any[] = [];
  token: string = '';
  displayBasic: boolean = false;
  mailBasic: boolean = false;
  selected: any[];
  mailForm: FormGroup;
  submitted = false;
  showingSchedule: string = '';
  defaultText: string = '';


  constructor(
    private realtorService: RealtorService,
    private environmentService: EnvironmentService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private papa: Papa
  ) {
    this.token = sessionStorage.getItem('msal.idtoken');
  }


  ngOnInit(): void {
    this.xClientId = this.environmentService.getXClientId();
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'dashboard/agent/signin-summary?code=' + this.environmentService.getIA9LightningKey();
    this.realtorService.getSigninSummary(url, this.xClientId, this.token).subscribe(response => {
      if (response.data) {
        this.listingInfo = response.data;
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
    });
    this.mailForm = this.formBuilder.group({
      tos: new FormControl('', [Validators.required]),
      subject: new FormControl('', Validators.required),
      message: new FormControl('', [Validators.required])
    });
  }

  get form() { return this.mailForm.controls; }

  // Open House attendees
  drip: string = '';
  share: string = '';
  agent: string = '';
  outAgent: string = '';

  openHouseAttendees(details) {
    this.attendeesDetails = [];
    this.xClientId = this.environmentService.getXClientId();
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'dashboard/agent/invites/' + `${details.address}/` + `${details.CreatedOn}/` + `${details.showingSchedule}` + '?code=' + this.environmentService.getIA9LightningKey();
    this.realtorService.getOpenHouseAttendees(url, this.xClientId, this.token).subscribe(response => {
      if (response.data) {
        this.attendeesDetails = response.data;
        for (let index = 0; index < this.attendeesDetails.length; index++) {
          if (this.attendeesDetails[index].isWorkingWithCompanyAgent === null && this.attendeesDetails[index].isWorkingWithOtherAgent === null) {
            this.drip = 'Yes';
            this.share = 'Yes';
            this.agent = 'No';
            this.outAgent = 'No';
          } else if (this.attendeesDetails[index].isWorkingWithCompanyAgent !== null) {
            this.drip = 'No';
            this.share = 'No';
            this.agent = 'Yes';
            this.outAgent = 'No';
          } else if (this.attendeesDetails[index].isWorkingWithOtherAgent !== null) {
            this.drip = 'No';
            this.share = 'No';
            this.agent = 'No';
            this.outAgent = 'Yes';
          }
        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
    });
  }

  showDetails(details) {
    this.selected = [];
    this.displayBasic = true;
    this.showingSchedule = details.showingSchedule;
    this.openHouseAttendees(details);
  }

  hideDialog() {
    this.displayBasic = false;
  }

  hideEmailDialog() {
    this.mailBasic = false;
  }

  shareMultiple() {
    if (this.selected.length !== 0) {
      this.mailBasic = true;
      for (let index = 0; index < this.selected.length; index++) {
        let selectedMails = this.selected.map(x => x.emailAddress);
        let removeDuplicateMails = selectedMails.filter((el, i, a) => i === a.indexOf(el));
        this.mailForm.patchValue({
          "tos": removeDuplicateMails
        });
        this.getAgentDetails();
      }
    } else {
      this.toastr.error('Select atleast one!...');
    }
  }

  shareSingle(attende) {
    this.mailBasic = true;
    this.mailForm.patchValue({
      "tos": Array(attende.emailAddress)
    });
    this.getAgentDetails();
  }

  sendMail() {
    this.submitted = true;
    if (this.mailForm.invalid) {
      return false;
    }
    this.xClientId = this.environmentService.getXClientId();
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'tools/sendmail?code=' + this.environmentService.getIA9LightningKey();
    this.realtorService.sendEmails(url, this.mailForm.value, this.xClientId, this.token).subscribe(response => {
      if (response['isSuccess'] === true) {
        this.submitted = false;
        this.mailForm.get("tos").setValue('');
        this.mailForm.get("subject").setValue('');
        this.mailForm.get("message").setValue(this.defaultText);
        this.spinner.hide();
        this.mailBasic = false;
        this.toastr.success('Mail sent successfully.');
      } else {
        this.toastr.warning('Something went wrong!..');
        this.spinner.hide();
      }
    }, error => {
      this.toastr.warning('Something went wrong!..');
      this.spinner.hide();
    });
  }

  getAgentDetails() {
    this.xClientId = this.environmentService.getXClientId();
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'agent/buyer/signin/' + '?code=' + this.environmentService.getIA9LightningKey();
    if (this.token) {
      this.realtorService.getRealtorProfileInfo(url, this.xClientId, this.token).subscribe(res => {
        if (res && res.data) {
          this.defaultText = `\n \n \n \n \n Thanks, \n ${res.data.agentName}, \n ${res.data.businessName} \n ${res.data.agentPhoneNumber}`
          this.spinner.hide();
        }
      }, error => {
        this.spinner.hide();
      })
    } else {
      this.spinner.hide();
      this.toastr.warning('Something went wrong!...');
    }
  }

  export() {
    const csvColumns = [
      'DATE',
      'ADDRESS',
      'DATE/TIME',
      'ATTENDESS'
    ];
    const csvRows: string[][] = [];
    let row: string[] = [];
    this.listingInfo.forEach((x) => {
      row = [];
      row.push(x.CreatedOn);
      row.push(x.address);
      row.push(x.showingSchedule);
      row.push(x.signinCount);
      csvRows.push(row);
    });
    const reportCsv = this.papa.unparse({ fields: csvColumns, data: csvRows });
    const blob = new Blob([reportCsv], { type: 'text/csv; charset=utf-8' });
    FileSaver.saveAs(blob, 'Openhousesummary' + '.csv');
  }

  exportOpenHouseAttendees() {
    const csvColumns = [
      'Name',
      'Mobile Number',
      'Email Address',
      'Drip',
      'Share',
      'Agent',
      'Out Agent',
    ];
    const csvRows: string[][] = [];
    let row: string[] = [];
    this.attendeesDetails.forEach((x) => {
      row = [];
      row.push(x.firstName + ' ' + x.lastName);
      row.push(x.primaryPhoneNumber);
      row.push(x.emailAddress);
      row.push(this.drip);
      row.push(this.share);
      row.push(this.agent);
      row.push(this.outAgent);
      csvRows.push(row);
    });
    const reportCsv = this.papa.unparse({ fields: csvColumns, data: csvRows });
    const blob = new Blob([reportCsv], { type: 'text/csv; charset=utf-8' });
    FileSaver.saveAs(blob, 'AttendeesDetails' + '.csv');
  }
}
