<app-header></app-header>
<section *ngIf="aid">
    <p-dialog header="Where is your open house located?" [modal]="true" [(visible)]="displayBasic"
        [style]="{width: '575px', padding: '10px'}" [baseZIndex]="10000" position="top" [draggable]="false"
        [resizable]="false">
        <div>
            <form novalidate #addressForm="ngForm" (ngSubmit)="addressForm.form.valid && onAdd(addressForm)">
                <div class="form-group col-xs-12">
                    <label>Property Address</label>
                    <input type="text" name="address" placeholder="full property address" class="form-control"
                        [(ngModel)]="model.address" #address="ngModel"
                        [ngClass]="{ 'is-invalid': addressForm.submitted && address.invalid }" required>
                    <div class="invalid-feedback" *ngIf="addressForm.submitted && address.invalid">
                        <p *ngIf="address.errors.required">Property address is required</p>
                    </div>
                </div>
                <div class="form-group col-xs-12">
                    <label>When is the date and time of your open house?</label>
                    <input type="text" name="showingSchedule" placeholder="{{ currentDate }} 9-5 PM"
                        class="form-control" [(ngModel)]="model.showingSchedule" #showingSchedule="ngModel"
                        [ngClass]="{ 'is-invalid': addressForm.submitted && showingSchedule.invalid }" required>
                    <div class="invalid-feedback" *ngIf="addressForm.submitted && showingSchedule.invalid">
                        <p *ngIf="showingSchedule.errors.required">Schedule is required</p>
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-block">Go</button>
                </div>
            </form>
        </div>
    </p-dialog>
    <!-- <div class="back_btn">
        <a routerLink="/dashboard">
            <img src="../../../assets/images/back_arrow.svg" alt="">
        </a>
    </div> -->
    <div class="container" style="background-color: #fdf7f8;">
        <div class="row center_div">
            <div class="col-lg-5 col-md-10 col-sm-12 col-xs-12">
                <div class="main_div" *ngIf="listingInfo">
                    <div class="imgSet">
                        <img src="{{ listingInfo.agentPhoto ? listingInfo.agentPhoto : '../../../assets/images/default.png'}}"
                            alt="" />
                    </div>
                    <div class="center_form">
                        <h1>Hello my name is {{listingInfo.agentName}}</h1>
                        <h5>Welcome to</h5>
                        <!-- <h6>{{listingInfo.businessAddress}}</h6> -->
                        <h6 style="text-transform: capitalize;">{{businessAddress}}</h6>
                        <p>
                            The Seller has requested that all guests sign in prior to viewing
                            the home
                        </p>
                        <hr class="line" />
                    </div>

                    <form class="form_des" [formGroup]="openHouseForm" (ngSubmit)="onSubmit()" *ngIf="show">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Name</label>
                            <input type="name" class="form-control" id="exampleInputName" aria-describedby="emailHelp"
                                formControlName="fullName" placeholder="Name"
                                [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" />
                            <div *ngIf="submitted && f.fullName.errors" class="text-danger">
                                <div *ngIf="f.fullName.errors.required">
                                    *Name is required
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="openHouseForm.controls.fullName.valid">
                            <label for="exampleInputPassword1">Mobile Number</label>
                            <input type="text" class="form-control" id="exampleInputNumber"
                                formControlName="primaryPhoneNumber" placeholder="Mobile Number"
                                [ngClass]="{ 'is-invalid': submitted && f.primaryPhoneNumber.errors }"
                                mask="(000) 000-0000" [showMaskTyped]="true" />
                            <div *ngIf="submitted && f.primaryPhoneNumber.errors" class="text-danger">
                                <div *ngIf="f.primaryPhoneNumber.errors.required">
                                    *Mobile number is required
                                </div>
                                <!-- <div *ngIf="f.primaryPhoneNumber.errors.pattern">
                                *Mobile number must be valid
                            </div> -->
                            </div>
                        </div>
                        <div class="form-group" *ngIf="openHouseForm.controls.primaryPhoneNumber.valid">
                            <label for="exampleInputPassword1">Email Address</label>
                            <input type="email" class="form-control" id="exampleInputEmail"
                                formControlName="emailAddress" placeholder="Email Address"
                                [ngClass]="{ 'is-invalid': submitted && f.emailAddress.errors }" />
                            <div *ngIf="submitted && f.emailAddress.errors" class="text-danger">
                                <div *ngIf="f.emailAddress.errors.required">*Email id is required</div>
                                <div *ngIf="f.emailAddress.errors.email">
                                    *Email id must be a valid
                                </div>
                            </div>
                        </div>
                        <section *ngIf="openHouseForm.controls.emailAddress.valid">
                            <p>Are you working with an agent from {{listingInfo.businessName}}?</p>
                            <div class="radio_btn">
                                <div class="form-check">
                                    <input type="radio" class="form-check-input" id="exampleCheck1"
                                        name="isWorkingWithCompanyAgent" value="true"
                                        formControlName="isWorkingWithCompanyAgent" />
                                    <label class="form-check-label" for="exampleCheck1">Yes</label>
                                </div>
                                <div class="form-check">
                                    <input type="radio" class="form-check-input" id="exampleCheck1"
                                        name="isWorkingWithCompanyAgent" value="false"
                                        formControlName="isWorkingWithCompanyAgent" />
                                    <label class="form-check-label" for="exampleCheck1">No</label>
                                </div>
                                <!-- <div *ngIf="submitted && f.isWorkingWithCompanyAgent.errors" class="text-danger">
                                <div *ngIf="f.isWorkingWithCompanyAgent.errors.required">
                                    *Please select either value
                                </div>
                            </div> -->
                            </div>
                            <p>Are you working with another agent?</p>
                            <div class="radio_btn">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isWorkingWithOtherAgent"
                                            value="true" formControlName="isWorkingWithOtherAgent" />Yes
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="isWorkingWithOtherAgent"
                                            value="false" formControlName="isWorkingWithOtherAgent" />No
                                    </label>
                                </div>
                                <!-- <div *ngIf="submitted && f.isWorkingWithOtherAgent.errors" class="text-danger">
                                <div *ngIf="f.isWorkingWithOtherAgent.errors.required">
                                    *Please select either value
                                </div>
                            </div> -->
                            </div>
                        </section>
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                    <div class="thnx_sec" *ngIf="!show">
                        <img src="../../../assets/images/thankYou.svg" alt="Img">
                        <h2>Thank You</h2>
                        <p>for signing in</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section *ngIf="!aid">
    <div class="back_btn">
        <a routerLink="/dashboard">
            <img src="../../../assets/images/back_arrow.svg" alt="">
        </a>
    </div>
    <div class="DashmainContainer">
        <div class="container">
            <div class="col-lg-12">
                <section>
                    <p>Unfortunately, we cannot process your request. Try after sometime or contact customer service at
                        <a href="tel:1-866-366-4446">1-866-366-4446</a>,
                        <a href="mailto:support@ia9inc.com">support@ia9inc.com</a>.
                    </p>
                </section>
            </div>
        </div>
    </div>
</section>
<section>
    <p-dialog header="" [modal]="true" [(visible)]="displayErrorPopup" [style]="{width: '575px', padding: '10px'}"
        [baseZIndex]="10000" position="center" [draggable]="false" [resizable]="false">
        <div class="ErrorContainer">
            <div class="container">
                <div class="col-lg-12">
                    <section>
                        <p>Something went wrong. Please try again later or contact customer service,
                            <a href="tel:1-866-366-4446">1-866-366-4446</a>,
                            <a href="mailto:support@ia9inc.com">support@ia9inc.com</a>.
                        </p>
                    </section>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="displayErrorPopup=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>
<app-footer></app-footer>