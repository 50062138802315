<app-header></app-header>
<div class="back_btn">
    <a routerLink="/dashboard">
        <img src="../../../assets/images/back_arrow.svg" alt="">
    </a>
</div>
<div class="DashmainContainer">
    <div class="tbl_des">
        <div class="card">
            <div class="tbl_des-header">
                <h3 class="page_title mt-3 mb-3">Open House Summary</h3>
                <button class="btn export_btn" (click)="export()">
                    <img src="../../../assets/images/ic-file-download.svg" alt="">Export
                </button>
            </div>
            <p-table [value]="listingInfo" selectionMode="single" [rows]="5" responsiveLayout="scroll"
                [showCurrentPageReport]="true" [paginator]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <!-- <th>Listing</th> -->
                        <th pSortableColumn="CreatedOn">Date<p-sortIcon field="CreatedOn">
                            </p-sortIcon>
                        </th>
                        <th>Address</th>
                        <th>Date/Time</th>
                        <th>Attendees</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer let-rowIndex="rowIndex">
                    <tr>
                        <!-- <td>Listing {{rowIndex}}</td> -->
                        <td>{{customer.CreatedOn}}</td>
                        <td>{{customer.address}}</td>
                        <td>{{customer.showingSchedule}}</td>
                        <td>{{customer.signinCount}}</td>
                        <td>
                            <button (click)="showDetails(customer)" pButton label="View" class="p-button-text"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="bottom-div"></div>
</div>
<section>
    <p-dialog header="Open House Attendees - {{showingSchedule}}" [modal]="true" [(visible)]="displayBasic"
        [style]="{width: '80%', padding: '10px'}" [baseZIndex]="10000" position="top" [draggable]="false"
        [resizable]="false">
        <div class="tbl_des" style="padding:0px">
            <div class="card">
                <p-table [value]="attendeesDetails" [(selection)]="selected" dataKey="id" responsiveLayout="scroll">
                    <ng-template pTemplate="caption">
                        <button class="btn export_btn" (click)="exportOpenHouseAttendees()">
                            <img src="../../../assets/images/ic-file-download.svg" alt="">Export
                        </button>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th pSortableColumn="CreatedOn">Date<p-sortIcon field="CreatedOn"></p-sortIcon>
                            </th>
                            <th>Name</th>
                            <th>Mobile Number</th>
                            <th>Email Address</th>
                            <th data-toggle="tooltip" data-placement="right" title="A drip email campaign has been sent.">Drip</th>
                            <th data-toggle="tooltip" data-placement="right" title="A link to lock this client into your domii app has been sent via text.">Share</th>
                            <th data-toggle="tooltip" data-placement="right" title="Is this client working with an Agent?">Agent</th>
                            <th data-toggle="tooltip" data-placement="right" title="Is this client working with an Agent outside of your company?">Out Agent</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-attende>
                        <tr>
                            <td>
                                <p-tableCheckbox [value]="attende"></p-tableCheckbox>
                            </td>
                            <td>{{attende.CreatedOn}}</td>
                            <td>{{attende.firstName}} {{attende.lastName}}</td>
                            <td>
                                <a style="color: #121212;"
                                    href="{{'tel:' + attende.primaryPhoneNumber}}">{{attende.primaryPhoneNumber}}
                                </a>
                            </td>
                            <td>
                                <a style="color: #121212;"
                                    href="{{'mailto:' + attende.emailAddress }}">{{attende.emailAddress}}
                                </a>
                            </td>
                            <td>{{drip}}</td>
                            <td>{{share}}</td>
                            <td>{{agent}}</td>
                            <td>{{outAgent}}</td>
                            <td>
                                <button style="padding: 0;
                                margin: 0; border-radius: 0;" pButton (click)="shareSingle(attende)">
                                    <img style="width:30px; height:30px;" src="../../../assets/images/share.png">
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Close" icon="pi pi-times" class="p-button-text"
                (click)="hideDialog()"></button>
            <button pButton pRipple label="Share" icon="pi pi-check" class="p-button-text"
                (click)="shareMultiple()"></button>
        </ng-template>
    </p-dialog>
</section>
<section>
    <p-dialog header="Share" [modal]="true" [(visible)]="mailBasic" [style]="{width: '80%', padding: '10px'}"
        [baseZIndex]="10000" position="top" [draggable]="false" [resizable]="false">
        <div class="">
            <div class="">
                <form [formGroup]="mailForm">
                    <div class="form-group col-xs-12">
                        <!-- <label for="email">Emails</label> -->
                        <textarea type="text" class="form-control" formControlName="tos" id="tos" placeholder="To"
                            [ngClass]="{'is-invalid': submitted && form.tos.errors}"></textarea>
                        <div class="invalid-feedback" *ngIf="submitted && form.tos.errors?.required">
                            <span>Please provide the email</span>
                        </div>
                    </div>
                    <div class="form-group  col-xs-12">
                        <!-- <label for="subject">Subject</label> -->
                        <input type="text" class="form-control" formControlName="subject" id="subject"
                            placeholder="Subject" [ngClass]="{'is-invalid': submitted && form.subject.errors}">
                        <div class="invalid-feedback" *ngIf="submitted && form.subject.errors?.required">
                            <span>Please mention the subject</span>
                        </div>
                    </div>
                    <div class="form-group  col-xs-12">
                        <!-- <label for="body">Body</label>  -->
                        <textarea type="text" class="form-control" formControlName="message" id="message"
                            placeholder="Message" [ngClass]="{'is-invalid': submitted && form.message.errors}" rows="10"
                            cols="100" [(ngModel)]="defaultText">
                        </textarea>
                        <div class="invalid-feedback" *ngIf="submitted && form.message.errors?.required">
                            <span>Please mention the message</span>
                        </div>
                    </div>
                    <!-- <div class="mail-signature">
                        <p>Thanks,<br> <span class="con">
                                Blaise Coco, Realtor
                            </span>
                            <br> <span class="con">
                                Coco, Early & Associates
                            </span>
                            <br> <span class="con">
                                9783754345
                            </span>
                        </p>
                    </div> -->
                </form>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Close" icon="pi pi-times" class="p-button-text"
                (click)="hideEmailDialog()"></button>
            <button pButton pRipple label="Send" icon="pi pi-check" class="p-button-text" (click)="sendMail()"></button>
        </ng-template>
    </p-dialog>
</section>
<app-footer></app-footer>
